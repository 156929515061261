import "./Star.css";
export function Star() {
  return (
    <div class="zigzag-background">
      <div class="zigzag">
        <p class="star-text">nu ook online te volgen</p>
      </div>
    </div>
  );
}
